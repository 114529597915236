jQuery(document).ready(function($) {
	/* Languages */
	var domain = window.location.host;
	if (domain.indexOf('www') >= 0) {
		hasWWW = true
		domain = domain.substr(4, domain.length - 1)
	}

	// Greek
	if (typeof window.langSwitchGreekURL !== 'undefined') {
		domainGreek = window.langSwitchGreekURL;
	}
	else {
		domainGreek = 'el.' + domain;
	}

	// English
	if (typeof window.langSwitchEnglishURL !== 'undefined') {
		domainEnglish = window.langSwitchEnglishURL;
	}
	else {
		domainEnglish = domain;
	}

	// German
	if (typeof window.langSwitchGermanURL !== 'undefined') {
		domainGerman = window.langSwitchGermanURL;
	}
	else {
		domainGerman = 'de.' + domain;
	}

	// Russian
	if (typeof window.langSwitchRussianURL !== 'undefined') {
		domainRussian = window.langSwitchRussianURL;
	}
	else {
		domainRussian = 'ru.' + domain;
	}

	var navbarsContent = "";
	if (typeof window.socialMedia.facebook !== "undefined") {
		navbarsContent += "<a target='_blank' rel='noopener' href='" + window.socialMedia.facebook + "' class='social-media-link header mobile w-inline-block w-clearfix'>" +
			"<img src='/20200410105500/local/public/shared/assets/images/websites/halloumicheese/facebook_centered_wht.svg' alt='Facebook' width='15' height='32' class='footer-social-icon-image'>" +
			"</a>"
	}
	if (typeof window.socialMedia.instagram !== "undefined") {
		navbarsContent += "<a target='_blank' rel='noopener' href='" + window.socialMedia.instagram + "' class='social-media-link header mobile w-inline-block w-clearfix'>" +
			"<img src='/20200410105500/local/public/shared/assets/images/websites/halloumicheese/instagram-2016-white.svg' alt='Instagram' width='15' height='32' class='footer-social-icon-image'>" +
			"</a>"
	}
	if (typeof window.socialMedia.youtube !== "undefined") {
		navbarsContent += "<a target='_blank' rel='noopener' href='" + window.socialMedia.youtube + "' class='social-media-link header mobile w-inline-block w-clearfix'>" +
			"<img src='/20200410105500/local/public/shared/assets/images/websites/halloumicheese/youtube_wht.svg' alt='LinkedIn' width='15' height='32' class='footer-social-icon-image'>" +
			"</a>"
	}
	if (typeof window.socialMedia.linkedin !== "undefined") {
		navbarsContent += "<a target='_blank' rel='noopener' href='" + window.socialMedia.linkedin + "' class='social-media-link header mobile w-inline-block w-clearfix'>" +
			"<img src='/20200410105500/local/public/shared/assets/images/websites/halloumicheese/linkedin_wht.svg' alt='YouTube' width='15' height='32' class='footer-social-icon-image'>" +
			"</a>"
	}
	if (typeof window.socialMedia.tiktok !== "undefined") {
		navbarsContent += "<a target='_blank' rel='noopener' href='" + window.socialMedia.tiktok + "' class='social-media-link header mobile w-inline-block w-clearfix'>" +
			"<img src='/20200410105500/local/public/shared/assets/images/websites/halloumicheese/icon-tiktok-white.svg' alt='TikTok' width='15' height='32' class='footer-social-icon-image'>" +
			"</a>"
	}
	navbarsContent += "<a href='//" + domainEnglish + "' class='language language-mobile first'><img src='/20200410105500/local/public/shared/assets/images/websites/halloumicheese/en-flag.png' alt='english flag'><span class='sronly'>english</span></a>"
	navbarsContent += "<a href='//" + domainGreek + "' class='language language-mobile'><img src='/20200410105500/local/public/shared/assets/images/websites/halloumicheese/cy-flag.png' alt='greek flag'><span class='sronly'>greek</span></a>"
	navbarsContent += "<a href='//" + domainGerman + "' class='language language-mobile'><img src='/20200410105500/local/public/shared/assets/images/websites/halloumicheese/de-flag.png' alt='german flag'><span class='sronly'>german</span></a>"
	navbarsContent += "<a href='//" + domainRussian + "' class='language language-mobile'><img src='/20200410105500/local/public/shared/assets/images/websites/halloumicheese/ru-flag.png' alt='russian flag'><span class='sronly'>russian</span></a>"

	/* Mobile menu */
	$( ".mobile-navigation-menu").mmenu({
		extensions: [
			"position-right"
		],
		navbar: {
			add: true,
			title: ""
		},
		screenReader: {
			aria: true,
			text: true
		},

		navbars: [{
			height: 1,
			content: [
				navbarsContent
			]
		}]
	}, {
		fixedElements: {
			elemInsertMethod: "prependTo"
		}
	});

	$(".mobile-menu-button").click(function() {
		if ($(".mobile-navigation-menu").hasClass("mm-opened")) {
			$(".mobile-navigation-menu").data("mmenu").close();
		}
		else {
			$(".mobile-navigation-menu").data("mmenu").open();
		}
	});
});
